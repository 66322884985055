/* form module: core */
form input,
form textarea,
form select {
  box-sizing: border-box;
  display: block;
  appearance: none;
  border: none;
}
form select {
  cursor: pointer;
}
form textarea {
  resize: vertical;
}

/* form module: theme */
form .grid-2-col {
  gap: 1.5rem 1rem;
  margin-bottom: 1.5rem;
}
form input,
form textarea,
form select {
  outline: 0.0625rem solid #e0e0e0;
  outline-offset: 0 !important;
  padding: 1.5625rem 2.25rem;
  font-size: 1.3125rem;
  width: 100%;
  height: 100%;
}
form input::placeholder,
form textarea::placeholder,
form select::placeholder {
  color: rgb(118, 118, 118);
}
form input:hover:not(:focus),
form textarea:hover:not(:focus),
form select:hover:not(:focus) {
  outline-color: #cccccc;
}
form select {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 9"><polygon points="15.636,0.225 8.5,7.361 1.364,0.225 0.657,0.932 7.793,8.068 8.5,8.775 9.207,8.068 16.343,0.932" style="fill:rgb(118, 118, 118);"/></svg>') right 1.875rem center/1.0625rem 0.5625rem no-repeat;
  padding-right: 5rem;
}
form select:hover, form select:focus {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 9"><polygon points="15.636,0.225 8.5,7.361 1.364,0.225 0.657,0.932 7.793,8.068 8.5,8.775 9.207,8.068 16.343,0.932" style="fill:black;"/></svg>');
}
form textarea {
  min-height: 15rem !important;
}
form button {
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  display: block;
  text-transform: uppercase;
  transition: background-color 0.2s ease-in, color 0.2s ease-in;
  padding: 1.25rem 2.25rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 25rem;
  color: #ffffff;
  background: #000000;
  margin-top: 2.5rem;
}
form button:hover {
  background: #932056;
  color: #000000;
}
form label {
  display: flex;
  align-items: center;
}
form input[type=checkbox] {
  all: revert;
  height: 20px;
  width: 20px;
  margin: 0 0.5rem 0 0;
}
form .wpcf7-list-item {
  margin: 0;
}